<template>
  <div class="reports-page-datatable">
    <el-table :data="data" style="width: 100%; font-size: 11px; padding: 0;" :max-height="screenHeight" class="el-table--mini">
      <el-table-column sortable prop="created_at" label="Data e Hora" width="130" />
      <el-table-column sortable prop="branch_name" label="Filial" width="120" />
      <el-table-column sortable prop="cargo_id" label="Carga" width="100" />
      <el-table-column sortable prop="truck_id" label="Placa" width="100" />
      <el-table-column sortable prop="driver_name" label="Motorista" width="230" />
      <el-table-column sortable prop="custumer" label="Cliente" width="300" />
      <el-table-column sortable prop="reason" label="Motivo" width="200" />
      <el-table-column sortable prop="time_cancel" label="Horário do cancelamento" width="150" />
      <el-table-column sortable prop="observation" label="Observação" width="600" />
      <el-table-column sortable prop="observation_driver" label="Observação Motorista" width="600" />
    </el-table>
  </div>
</template>

<script>
import exportJson from '@/helpers/exportXLSX'

export default {
  name: 'report-table-list-of-error',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {
        return {
          veiculo: 'truck_id',
          filial: 'branch_name',
          filial_id: 'branch_id',
          status: 'cargo_status',
          carga: 'cargo_id',
          cliente: 'custumer',
          dataCriacao: 'created_at',
          motorista: 'driver_name',
          motivo: 'reason ',
          observacao: 'observation',
          observacaoMotorista: 'observation_driver',
          hora_cancela: 'time_cancel',
        }
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    screenHeight() {
      return (window.innerHeight -260)
    }
  },
  watch: {},
  mounted() {
    this.$emit('loaded')
  },
  methods: {
    DownloadXLS() {
      exportJson.setConfig({ debug: true })
      exportJson.DownloadXLS(this.data, this.fields)
    },
    DownloadCSV() {
      exportJson.DownloadCSV(this.data, this.fields)
    },
  },
}
</script>
